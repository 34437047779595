
<nav [ngClass]="{'active': sidebarActive}" [ngStyle]="{ 'width.px': sidebarActive ? 50 : 'auto' }">
    <ul>
        <div style="display:flex;justify-content: space-between;align-items: center;">
        <img class="sidebarLogo" src="assets/images/Backoffice.png" alt="babylon-layout" [ngStyle]="{ 'display': sidebarActive ? 'block' : 'none' }">
        <div style="width:20%" class="mobile">
            <ul>
            <li style="padding: 0;">
                <a class="toggle" (click)="toggleSidebar()" style="background-color: transparent;">
                    <span class="icon"><i class="fas fa-bars"></i></span>
                </a>
            </li>
        </ul>
    </div>
    </div>
        <sidebar class="sidebarS"  [sidebarActive]="sidebarActive" (sidebarToggled)="toggleSidebar()"></sidebar>
    </ul>
</nav>
<div class="layout-wrapper layout-menu-light"  [ngClass]="{
    'layout-horizontal': menuMode === 'horizontal',
    'layout-popup': menuMode === 'popup',
    'layout-overlay': menuMode === 'overlay',
    'layout-static': menuMode === 'static',
    'layout-static-inactive': menuMode === 'static' && !menuActive,
    'layout-mobile-active': menuActive
    }">
    
    <div>
        <!-- <app-menu>
        </app-menu> -->

        <div class="layout-main" style="padding-top:0" [ngClass]="{
            'layout-content-expanded': sidebarActive,
            'layout-content-collapsed': !sidebarActive
        }">
        <div class="layout-topbar" style="position: sticky; top: 0;background-color: #0f172a;display: flex;justify-content: space-between;">
            <div style="width:20%" class="web">
            <ul>
            <li style="padding: 0;">
                <a class="toggle" (click)="toggleSidebar()" style="background-color: transparent;">
                    <span class="icon"><i class="fas fa-bars"></i></span>
                </a>
            </li>
        </ul>
    </div>
            <app-header style="width: 80%;"></app-header>
        </div>
            <div class="layout-content">
              
                <router-outlet></router-outlet>
            </div>

       

        <div class="layout-mask"></div>
        <app-footer *ngIf="user.userType !== -1"></app-footer>
    </div>
    </div>
   
</div>


