
    <!-- <a routerLink="/pages" class="layout-topbar-logo">
        <span style="color: #000;line-height: 44px;font-size: 20px;">BackOffice</span>
    </a> -->
    <a (click)="app.onMenuClick($event);app.menuActive = !app.menuActive;" id="menu-button" class="layout-menu-button">
        <i class="pi pi-bars"></i>
    </a>
    <a (click)="userDisplay = !userDisplay" id="topbar-menu-button"><i class="pi pi-ellipsis-v"></i></a>
    <!-- <div class="notification-icon" (click)="toggleDropdown()">
        <i class="fas fa-bell"></i>
        <span class="notification-count">{{withdrawRequest}}</span>
        <div class="ui-g-12 ui-md-8 dropdown-container" *ngIf="showDropdown">
            <p-dropdown [options]="totalUsers" placeholder="{{ 'Seçiniz' | translate }}" filter="true"
                 name="domainId"></p-dropdown>
        </div>
      </div>  -->

    
    

    <ul class="topbar-menu fadeInDown animated" id="topbar-menu" [ngClass]="{'topbar-menu-visible':userDisplay}">
        <li class="user-profile" [ngClass]="{'active-topmenuitem':userDisplay}">

            <div class="user-info">
                <!-- <span class="username">{{app.user.username}}</span> -->
                <span class="limit" *ngIf="app.user.userType === 0 || app.user.userType === -1"><i class="fa fa-credit-card"></i> {{app.user.limit | currency:'':''}}</span>
            </div>
            <a (click)="userDisplay = !userDisplay" id="user-display" style="cursor: pointer;">
                <img alt="babylon-layout" src="assets/images/avatar.png">
            </a>
            <ul class="fadeInDown" style="z-index: 1;">
                <li role="menuitem">
                    <p-dropdown [options]="languages" [style]="{width: '100%'}"
                                [ngModel]="translate.currentLang" appendTo="body"
                                (onChange)="changeLanguage($event.value)">
                        <ng-template pTemplate="selectedItem" let-item>
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                            <span>{{option.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>

                </li>
                
                <li role="menuitem">
                        <a class="flex" (click)="app.logout()" style="cursor: pointer;">
                        <i class="fas fa-fw fa-sign-out-alt"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <div class="notification-icon" *ngIf="this.app.user.userType === 1">
        
        <div class="mr-25" (click)="toggleEnvelopeDropdown()">
            <i class="fas fa-envelope"></i>
            <span *ngIf="pendingMessagesRequest" class="messages-count">{{ pendingMessagesRequest }}</span>
            </div>
            <ng-container *ngIf="showEnvelopeDropdown">
                <div class="ui-g-12 ui-md-12 dropdown-containers" [class.open]="dropdownEnvelopeOpen">
                    <ul class="overflow">
                        <div>
                            <h3 class="notifi">
                                Support Tickets
                            </h3>
                        </div>
                        <!-- <li *ngFor="let user of totalMessages" (click)="selectEnvelopeOption(user.value)">
                            <span [ngClass]="{ 'bold-option': user === selectedOption }">{{ user.label }}</span>
                        </li> -->
                        <li *ngFor="let user of totalMessages" (click)="selectEnvelopeOption(user.value)">
                            <span [ngClass]="{ 'bold-option': user === selectedOption }" [innerHTML]="user.label"></span>
                        </li>
                        
                    </ul>
                </div>
                
                
            </ng-container>

        <div (click)="toggleDropdown()" >
        <i class="fas fa-bell" ></i>
        <span *ngIf="withdrawRequest" class="notification-count">{{ withdrawRequest }}</span>
        </div>
        <ng-container *ngIf="showDropdown">
            <div class="ui-g-12 ui-md-12 dropdown-container" [class.open]="dropdownOpen">
                <ul class="overflow">
                    <div>
                        <h3 class="notifi">
                            Withdraw Notifications
                        </h3>
                    </div>
                    <li *ngFor="let user of totalUsers" (click)="selectOption(user.value)">
                        <span [ngClass]="{ 'bold-option': user === selectedOption }">{{ user.label }}</span>
                    </li>
                </ul>
            </div>
            
            
        </ng-container>
    </div>

