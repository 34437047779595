import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { PagesComponent } from '../../../pages/pages.component';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../../app.config.service';
import { ResellerService } from "../../../@core/services/reseller.service";
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UserType } from 'src/app/@core/modals/user-type';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit {
    userDisplay: boolean = false;
    languages = [];
    // withdrawRequest:any;
    shouldPlayBeep: boolean  = false;
    showDropdown: boolean = false;
    pendingMessagesRequest:any;
    showEnvelopeDropdown: boolean = false;
    placeholderText: string = 'Select an option'; // Static placeholder text
    totalUsers: any[] = []; // Initialize totalUsers as an empty array
    totalCountMessages: any[] = [];
    getTotalAmount:any;
    boldOptionIndex: number = -1; // Index of the currently bolded option
    selectedOption: any; // Selected option object
    beepSound: HTMLAudioElement;
    totalMessages:any;
    withdrawRequest:any;
    previousCount: number | null = null;
    previousMessage: number | null = null;
    dropdownOpen: boolean = false;
    private subscription: Subscription;
    constructor(private sanitizer: DomSanitizer,public translate: TranslateService,private elementRef: ElementRef, public app: PagesComponent, public config: AppConfigService,private router: Router,private resellerServrice: ResellerService,) {
        this.beepSound = new Audio();
    this.beepSound.src = '../../../../assets/sounds/beep-08b.mp3';
    }

    ngOnInit() {
        const languages = this.config.getLanguages();
        this.languages = languages.map((lang) => {
            return {
                label: lang,
                value: lang
            };
        });
        if(this.app.user.userType !== UserType.EMPLOYEE) {
            this.fetchWithdrawData();
            this.startFetchingWithdrawalCount();
            this.fetchMessageWithdrawData()
            this.startMessageFetchingWithdrawalCount();
        }
    }
    
         // Call stopFetchingWithdrawalCount method where you want to stop fetching
        // withdrawal count, for example, in ngOnDestroy method:
        ngOnDestroy(): void {
            this.stopFetchingWithdrawalCount();
        }
        selectEnvelopeOption(option: any, index: number): void {
            this.selectedOption = option;
            this.boldOptionIndex = index;
            // Navigate to another page upon option selection
            this.router.navigate(['/pages/support']); 
            // Make API call when option is clicked
            this.makeMessageAPICall(option)
            this.closeDropdown();
            
        }
    selectOption(option: any, index: number): void {
        this.selectedOption = option;
        this.boldOptionIndex = index;
        // Navigate to another page upon option selection
        this.router.navigate(['/pages/accounting/withdraw']); 
        // Make API call when option is clicked
        this.makeAPICall(option);
        this.closeDropdown();
        
    }

    @HostListener('document:click', ['$event'])
    clickOutsideDropdown(event: any) {
      if (!this.elementRef.nativeElement.contains(event.target)) {
        this.showDropdown = false; // Close the dropdown when clicking outside
        this.showEnvelopeDropdown = false;
      }}
      closeDropdown() {
        this.showDropdown = false;
        this.showEnvelopeDropdown = false;
      }
    makeAPICall(id: number): void {
        const payload = {
            isRead: '1',
        };
    
        this.resellerServrice.sendNotifyCounts(id,payload).subscribe(
            (res) => {
                this.resellerServrice.getPendingWithdrawCount().subscribe(
                    (res) => {
                        // this.withdrawRequest = res.data.totalCount;
                    },
                );
            },
            (error) => {
            }
        );
    }
    makeMessageAPICall(id: number): void {
        const payload = {
            isRead: '1',
        };
    
        this.resellerServrice.sendMessageNotifyCounts(id,payload).subscribe(
            (res) => {
                this.resellerServrice.getPendingMessageCount().subscribe(
                    (res) => {
                        // this.pendingMessagesRequest = res.data.totalCount;
                    },
                );
            },
            (error) => {
            }
        );
    }

    fetchMessageWithdrawData(): void {
        this.resellerServrice.getPendingMessageCount().subscribe(
            (res) => {
                this.pendingMessagesRequest = res.data.totalCount;
                
               
                if (this.previousMessage !== null && this.pendingMessagesRequest > this.previousMessage) {
                    
                    this.beepSound.play();
                  }
  
         
          this.previousMessage = this.pendingMessagesRequest;
                
                const withdrawalAmounts = {};
        
                // Map user data to label and value format for dropdown options
                this.totalCountMessages = res.data.message.map(data => {
                    // Populate withdrawalAmounts object with withdrawal ID and amount
                    withdrawalAmounts[data.message.id] = data.message.ticketType;
        
                    return { label: data.user.username, value: data.message.id };
                });
        
                // Concatenate text with each label and its corresponding amount
                this.totalMessages = this.totalCountMessages.map((user) => {
                    // Get the amount corresponding to the withdrawal ID
                    const amount = withdrawalAmounts[user.value];
                    // Concatenate text before label with the amount
                    user.label = this.sanitizer.bypassSecurityTrustHtml(`<b>${user.label}</b> has created ${amount} type of ticket.`);
                    return user;
                });
            },
            (error) => {
            }
        );
        
    }
    startMessageFetchingWithdrawalCount(): void {
        // Call the API immediately
        this.fetchMessageWithdrawData();
    
        // Call the API every minute
        this.subscription = interval(60000).subscribe(() => {
            this.fetchMessageWithdrawData();
        });
    }

    fetchWithdrawData(): void {
        this.resellerServrice.getPendingWithdrawCount().subscribe(
            (res) => {
                this.withdrawRequest = res.data.totalCount;
                
                // Check if the count has increased
                if (this.previousCount !== null && this.withdrawRequest > this.previousCount) {
                    // Play the beep sound
                    this.beepSound.play();
                  }
  
          // Update the previous count
          this.previousCount = this.withdrawRequest;
                // Initialize an object to store withdrawal amounts by their IDs
                const withdrawalAmounts = {};
        
                // Map user data to label and value format for dropdown options
                this.totalUsers = res.data.withdrawals.map(data => {
                    // Populate withdrawalAmounts object with withdrawal ID and amount
                    withdrawalAmounts[data.withdrawal.id] = data.withdrawal.amount;
        
                    return { label: data.user.username, value: data.withdrawal.id };
                });
        
                // Concatenate text with each label and its corresponding amount
                this.totalUsers = this.totalUsers.map((user) => {
                    // Get the amount corresponding to the withdrawal ID
                    const amount = withdrawalAmounts[user.value];
                    // Concatenate text before label with the amount
                    user.label =  user.label + ' has requested a withdrawal of ' + amount;
                    return user;
                });
            },
            (error) => {
            }
        );
        
    }

    // Start fetching withdrawal count every minute
startFetchingWithdrawalCount(): void {
    // Call the API immediately
    this.fetchWithdrawData();

    // Call the API every minute
    this.subscription = interval(60000).subscribe(() => {
        this.fetchWithdrawData();
    });
}

// Stop fetching withdrawal count
stopFetchingWithdrawalCount(): void {
    // Unsubscribe from the interval subscription
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
}
    // playBeep(): void {
    //     // Play the beep sound
    //     this.beepSound.play();
    //   }
    toggleEnvelopeDropdown() {
        this.showEnvelopeDropdown = !this.showEnvelopeDropdown;
        this.showDropdown = false;
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        this.showEnvelopeDropdown = false;
    }
    changeLanguage(userLang: any) {
        this.translate.use(userLang);
        localStorage.setItem('lang', userLang);
    }
}

