import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TerminalService {

    constructor(private api: ApiService) {
    }

    getMembers(query?: any) {
        return this.api.get(this.api.path + '/terminal', query);
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/terminal', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id, data);
    }

    delete(id: any) {
        return this.api.delete(this.api.path + '/terminal/' + id);
    }

    getMemberOverview(id: number) {
        return this.api.get(this.api.path + '/terminal/' + id + '/overview');
    }

    getMemberWallet(id: number) {
        return this.api.get(this.api.path + '/terminal/' + id + '/wallet');
    }

    getMember(id: number) {
        return this.api.get(this.api.path + '/terminal/' + id);
    }

    forgetPassword(id: number) {
        return this.api.post(this.api.path + '/terminal/' + id + '/forget');
    }

    resetGoogleAuth(id: number) {
        return this.api.post(this.api.path + '/terminal/' + id + '/reset');
    }

    getWithdrawalAmount(id: number) {
        return this.api.get(this.api.path + '/terminal/' + id + '/isWithdrawal');
    }

    blockWallet(id: number, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/walletBlocked', data);
    }

    updateWallet(id: any, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/wallet', data);
    }

    getMemberTransactions(id: number, query?: any) {
        return this.api.get(this.api.path + '/transaction/', { ...query, ...{ userId: id } });
    }

    getMemberCoupons(id: number, query?: any) {
        return this.api.get(this.api.path + '/coupon/', { ...query, ...{ userId: id } });
    }

    getMemberBonusRules(id: number, query?: any) {
        return this.api.get(this.api.path + '/terminal/' + id + '/bonus', query);
    }

    addBonus(id: number, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/bonus', data);
    }

    getMemberNotes(id: number, query?: any) {
        return this.api.get(this.api.path + '/terminal/' + id + '/note', query);
    }

    insertMemberNote(id: number, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/note', data);
    }

    getMemberLimits(id: number, query?: any) {
        return this.api.get(this.api.path + '/terminal/' + id + '/limit', query);
    }

    getMemberAccessLogs(id: number, query?: any) {
        return this.api.get(this.api.path + '/terminal/' + id + '/access', query);
    }

    removeMemberSession(id: any) {
        return this.api.delete(this.api.path + '/terminal/' + id + '/online');
    }

    addMember(data: any) {
        return this.api.post(this.api.path + '/terminal', data);
    }

    getUserOptions(query?: any) {
        return this.api.get(this.api.path + '/terminal/option', query);
    }

    updateUserOption(id: any, userId: any, formData: any) {
        return this.api.post(this.api.path + '/terminal/' + userId + '/option/' + id, formData);
    }

    updateTerminalConfig(id: any, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/config', data);
    }

    reinstallTerminal(id: any, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/reinstall', data);
    }

    updateMachineHash(id: any, data: any) {
        return this.api.post(this.api.path + '/terminal/' + id + '/update-machine-hash', data);
    }

    setMask(id: number, mask: boolean) {
        return this.api.post(this.api.path + '/terminal/setmask', { id: id, mask: mask });
    }

    panic() {
        return this.api.post(this.api.path + '/terminal/panic', {});
    }
}
