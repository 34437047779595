import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ResellerService {

    constructor(private api: ApiService) {
    }
    sendReferralCode(id: any,query?: any,) {
        return this.api.post(this.api.path + '/reseller/' + id + '/generateCode', query);
    }
    sendNotifyCounts(withdrawId: any,query?: any,) {
        return this.api.post(this.api.path + '/withdraw/' + withdrawId + '/isReadNotify', query);
    }
    sendMessageNotifyCounts(id: any,query?: any,) {
        return this.api.post(this.api.path + '/message/' + id + '/readNotification', query);
    }
    getPendingMessageCount(query?: any,) {
        return this.api.get(this.api.path + '/message/pendingTicketsNotify', query);
    }
    getPendingWithdrawCount(query?: any,) {
        return this.api.get(this.api.path + '/withdraw/pendingWithdrawCount', query);
    }
    getPendingWithdrawCountNumber(query?: any,) {
        return this.api.get(this.api.path + '/withdraw/pendingWithdrawCountNumber', query);
    }
    
    getPendingDepositCount(query?: any,) {
        return this.api.get(this.api.path + '/deposit/pendingDepositCount', query);
    }
    
    getResellers(query?: any, parentId?: any) {
        return this.api.get(this.api.path + '/reseller', { ...query, ...{ parentId } });
    }
    getDocumentsAdmin(query?: any) {
        return this.api.get('admin/user-documents', query);
    }
    getAllResellers(resellerId?: any) {
        return this.api.get(this.api.path + '/reseller/all', { resellerId });
    }
    getDocumentsResellers(resellerDocumentId?: any) {

        return this.api.get('reseller/reseller-user-documents/' + resellerDocumentId);

    }
    // getAllRootResellers() {
    //     return this.api.get(this.api.path + '/reseller/all/root');
    // }
    getAllRootResellers() {
        return this.api.post('admin/promotions-domain');
    }
    getAllDomainResellers(resId: any) {
        return this.api.get(this.api.path + '/domains/' + resId + '/getDomain');
    }
    // getAllDomainResellers(resellerId?: any) {
    //     return this.api.get(this.api.path +'/domains/', { resellerId });
    // }
    

    // getAllDomains() {
    //     return this.api.get(this.api.path + '/promotions-domain');
    // }

    removeResellerSession(id: any) {
        return this.api.delete(this.api.path + '/reseller/' + id + '/online');
    }

    getReseller(id: number) {
        return this.api.get(this.api.path + '/reseller/' + id);
    }
    getResellerDomain(id: number) {
        return this.api.get(this.api.path + '/reseller/' + id + '/referralDomain');
    }

    insert(data: any) {
        return this.api.post(this.api.path + '/reseller', data);
    }

    update(id: any, data: any) {
        return this.api.post(this.api.path + '/reseller/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete(this.api.path + '/reseller/' + id);
    }

    updateOption(id: any, data: any) {
        return this.api.post(this.api.path + '/reseller/' + id + '/option', data);
    }

    updateDomain(id: any, data: any) {
        return this.api.post(this.api.path + '/reseller/' + id + '/domain', data);
    }

    getMembers(id: number, query?: any) {
        return this.api.get(this.api.path + '/membership/', { ...query, ...{ resellerId: id } });
    }

    getTransactions(id: number, query?: any) {
        return this.api.get(this.api.path + '/reseller/transaction', { ...query, ...{ resellerId: id } });
    }

    updateLimit(id: any, data: any) {
        return this.api.post(this.api.path + '/reseller/' + id + '/limit', data);
    }

    getCurrentProgressPayment(id, query?: any) {
        return this.api.get(this.api.path + '/reseller/' + id + '/progressPayment', query);
    }

    getProgressPaymentHistory(id, query?: any) {
        return this.api.get(this.api.path + '/reseller/' + id + '/progressPayment/history', query);
    }

    getSubsPaymentProgress(id, query?: any) {
        return this.api.get(this.api.path + '/reseller/' + id + '/sub/progressPayment', query);
    }

    finalizeProgressPayment(id: number, data: any) {
        return this.api.post(this.api.path + '/reseller/' + id + '/progressPayment', data);
    }

    getResellerStats(id: any, query?: any) {
        return this.api.get(this.api.path + '/reseller/' + id + '/progressPayment/stats', query);
    }

    updateCouponOption(id: any, formData: any) {
        return this.api.post(this.api.path + '/reseller/' + id + '/couponOption', formData);

    }

    getResellerOptions(query?: any) {
        return this.api.get(this.api.path + '/reseller/option', query);
    }

    updateResellerOption(id: any, resellerId: any, formData: any) {
        return this.api.post(this.api.path + '/reseller/' + resellerId + '/option/' + id, formData);
    }

    getAccessLogs(resellerId: number, formData: any) {
        return this.api.get(this.api.path + '/reseller/' + resellerId + '/access', formData);
    }
    getAllResellersReminder() {
        return this.api.get(this.api.path + '/reseller/reminder');
    }

    getResellerDomains(id: any) {
        return this.api.get(this.api.path + '/domains/' + id);
    }

}
