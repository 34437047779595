import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OperatorService {

    constructor(private api: ApiService) {
    }

    getOperators(query?: any) {
        return this.api.get('admin/operator', query);
    }

    insert(data: any) {
        return this.api.post('admin/operator', data);
    }

    update(id: any, data: any) {
        return this.api.post('admin/operator/' + id, data);
    }

    delete(id: number | any) {
        return this.api.delete('admin/operator/' + id);
    }

    getOperatorRoles(id: number) {
        return this.api.get('admin/operator/' + id + '/role');
    }

    getAllUsersOnline(query?: any) {
        return this.api.get('admin/usersOnline', query);
    }

    saveOperatorRoles(id: number, roles: any) {
        return this.api.post('admin/operator/' + id + '/role', roles);
    }

    removeSession(id: any) {
        return this.api.delete('admin/operator/' + id + '/online');
    }
    getUsersOnline(query?: any) {
        return this.api.get('admin/usersOnline', query);
    }
}
